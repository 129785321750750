.user-details {
    margin-bottom: 44px;
    padding-top: 40px;
    padding-bottom: 20px;
    margin: 0 auto;
    text-align: center;
}

.user-avatar-circle {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    line-height: 60px;
}

.user-avatar-circle > * {
    line-height: 120px;
    font-size: 40px;
    position: relative !important;
    left: 0 !important;
}

.user-summary {
    text-align: center;
    padding-top: 20px;
}

@media (min-width: 576px) {
    .user-details {
        text-align: left;
        display: table;
    }    

    .user-avatar, .user-summary {
        float: left;
        text-align: left;
    }

    .user-summary {
        padding-top: 0;
        padding-left: 40px;
        width: calc(100% - 120px);
    }
}

.user-summary .full-name {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0,0,0,0.85);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.user-summary .username {
    font-size: 20px;
}

.user-summary .user-joined {
    margin-top: 5px;
}

@media (min-width: 768px) {
    .user-summary .full-name {
        font-size: 30px;
    }    
}
