.app-header {
    background: #fff;
    padding: 0 24px;
    border-bottom: 1px solid #e8e8e8;
}

.app-branding {
    float: left;
}

.app-title {
    font-size: 1.35em;
}

.app-options .ant-menu-horizontal {
    border-bottom: none;
}

.logout-btn {
    color: inherit;
    padding: 0;
}

.ant-menu-horizontal .ant-menu-item::after {
    border: none !important;
}

.profile-menu {
    margin-right: -20px;
}
