.login-content {
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 2px;
    max-width: 500px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding: 35px;
}

.login-title {
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
}

.signup-link {
    text-align: center;
}
